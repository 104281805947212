import useSWRImmutable from 'swr/immutable'

const useMenu = (key: string) => {
  const { data: open, mutate } = useSWRImmutable(`menu:${key}`, null, {
    fallbackData: false,
  })
  const setMenuOpen = (value: boolean) => mutate(value)

  return { open, setMenuOpen }
}

export default useMenu
