import { useEffect } from 'react'
import Head from 'next/head'
import Router from 'next/router'
import Script from 'next/script'
import { ThemeProvider } from 'next-themes'
import 'normalize.css/normalize.css'
import 'keen-slider/keen-slider.min.css'
import 'styles/global.scss'
import useMenu from 'hooks/useMenu'
import { PreloadType } from 'components/type/v3'
import { Analytics } from '@vercel/analytics/react'
import PlausibleProvider from 'next-plausible'

export default function AnalogueApp({ Component, pageProps }) {
  const { setMenuOpen } = useMenu('main-menu')
  const { setMenuOpen: setSubNavOpen } = useMenu('subnav')
  useEffect(() => {
    const handleRouteChange = (url) => {
      setMenuOpen(false)
      setSubNavOpen(false)
    }
    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [setMenuOpen, setSubNavOpen])
  return (
    <>
      <PlausibleProvider domain="analogue.co">
        <ThemeProvider
          defaultTheme="system"
          enableSystem={true}
          forcedTheme={Component.theme || null}
        >
          <Component {...pageProps} />
        </ThemeProvider>
        <Analytics />
        <PreloadType />
        <Head>
          <link rel="preconnect" href="https://images.analogue.co" />
          <link rel="preconnect" href="https://assets.analogue.co" />
          <link
            rel="preload"
            as="font"
            crossOrigin="crossOrigin"
            type="font/woff2"
            href="https://assets.analogue.co/type/lineto-circular-bold.woff2"
          />
          <link
            rel="preload"
            as="font"
            crossOrigin="crossOrigin"
            type="font/woff2"
            href="https://assets.analogue.co/type/subset/lineto-circular-medium.woff2"
          />
          <link
            rel="preload"
            as="font"
            crossOrigin="crossOrigin"
            type="font/woff2"
            href="https://assets.analogue.co/type/lineto-circular-book.woff2"
          />
          <link
            rel="preload"
            as="font"
            crossOrigin="crossOrigin"
            type="font/woff2"
            href="https://assets.analogue.co/type/lineto-mono-book.woff2"
          />
          <link
            rel="preload"
            as="font"
            crossOrigin="crossOrigin"
            type="font/woff2"
            href="https://assets.analogue.co/type/unica77mono-regular.b11b328c00813bd505122b5280cd3ca4.woff2"
          />
          <link
            rel="preload"
            as="font"
            crossOrigin="crossOrigin"
            type="font/woff2"
            href="https://assets.analogue.co/type/unica77mono-bold.2baf6127ae379b04fbc06f6d92e7ab9d.woff2"
          />
        </Head>
        <style global jsx>{`
          @font-face {
            font-family: 'Circular-Bold';
            src: url('https://assets.analogue.co/type/lineto-circular-bold.eot');
            src: url('https://assets.analogue.co/type/lineto-circular-bold.eot?#iefix')
                format('embedded-opentype'),
              url('https://assets.analogue.co/type/lineto-circular-bold.woff2')
                format('woff2'),
              url('https://assets.analogue.co/type/lineto-circular-bold.woff')
                format('woff');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
          }

          @font-face {
            font-family: 'Circular-Book';
            src: url('https://assets.analogue.co/type/lineto-circular-book.eot');
            src: url('https://assets.analogue.co/type/lineto-circular-book.eot?#iefix')
                format('embedded-opentype'),
              url('https://assets.analogue.co/type/lineto-circular-book.woff2')
                format('woff2'),
              url('https://assets.analogue.co/type/lineto-circular-book.woff')
                format('woff');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
          }

          @font-face {
            font-family: 'Circular-Medium';
            src: url('https://assets.analogue.co/type/subset/lineto-circular-medium.eot');
            src: url('https://assets.analogue.co/type/subset/lineto-circular-medium.eot?#iefix')
                format('embedded-opentype'),
              url('https://assets.analogue.co/type/subset/lineto-circular-medium.woff2')
                format('woff2'),
              url('https://assets.analogue.co/type/subset/lineto-circular-medium.woff')
                format('woff');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
          }

          @font-face {
            font-family: 'CircularXX';
            src: url('https://assets.analogue.co/type/circular-light.e41b1ae54b4a3dc789f64c5c8684cff0.woff2')
                format('woff2'),
              url('https://assets.analogue.co/type/circular-light.4406992fe5cdea90e448b40bd3419ce7.woff')
                format('woff');
            font-weight: 100 200;
            font-style: normal;
            font-display: swap;
          }

          @font-face {
            font-family: 'CircularXX';
            src: url('https://assets.analogue.co/type/circular-thin.5fd4b3e6c719865ba3fd84b26b9ff6f7.woff2')
                format('woff2'),
              url('https://assets.analogue.co/type/circular-thin.c34f0664e3990fbf0ab8435d5bf0a351.woff')
                format('woff');
            font-weight: 300;
            font-style: normal;
            font-display: swap;
          }

          @font-face {
            font-family: 'CircularXX';
            src: url('https://assets.analogue.co/type/circular-regular.4445da5b8f9ac63a0fa7151b5029f567.woff2')
                format('woff2'),
              url('https://assets.analogue.co/type/circular-regular.5460a349153d395d58757053bb6bccf1.woff')
                format('woff');
            font-weight: 400;
            font-style: normal;
            font-display: swap;
          }

          @font-face {
            font-family: 'CircularXX';
            src: url('https://assets.analogue.co/type/circular-medium.d5f19ad80c7ce40747a0b5ca8f3c25b5.woff2')
                format('woff2'),
              url('https://assets.analogue.co/type/circular-medium.7a139c9606fdc769f02761d3ddefb393.woff')
                format('woff');
            font-weight: 500;
            font-style: normal;
            font-display: swap;
          }

          @font-face {
            font-family: 'CircularXX';
            src: url('https://assets.analogue.co/type/circular-bold.05c3f17b5ed5a6bc5f9251565b71a081.woff2')
                format('woff2'),
              url('https://assets.analogue.co/type/circular-bold.ef2c65651cfcb871be1dd2f7a1bfce0d.woff')
                format('woff');
            font-weight: 600;
            font-style: normal;
            font-display: swap;
          }

          @font-face {
            font-family: 'CircularXX';
            src: url('https://assets.analogue.co/type/circular-italic.bb3d06996489990c835b496afd2611a4.woff2')
                format('woff2'),
              url('https://assets.analogue.co/type/circular-italic.068448b463aa7eca48a4076c9bdfb848.woff')
                format('woff');
            font-weight: 400;
            font-style: italic;
            font-display: swap;
          }

          @font-face {
            font-family: 'Mono-Book';
            src: url('https://assets.analogue.co/type/lineto-mono-book.eot');
            src: url('https://assets.analogue.co/type/lineto-mono-book.eot?#iefix')
                format('embedded-opentype'),
              url('https://assets.analogue.co/type/lineto-mono-book.woff2')
                format('woff2'),
              url('https://assets.analogue.co/type/lineto-mono-book.woff')
                format('woff');
            font-weight: 100 900;
            font-style: normal;
            font-display: swap;
          }

          @font-face {
            font-family: 'Unica77Mono';
            src: url('https://assets.analogue.co/type/unica77mono-bold.2baf6127ae379b04fbc06f6d92e7ab9d.woff2')
                format('woff2'),
              url('https://assets.analogue.co/type/unica77mono-bold.1deccdd467fa4965726a1e1bd4f2bc61.woff')
                format('woff');
            font-weight: 500 900;
            font-style: italic;
            font-display: swap;
          }

          @font-face {
            font-family: 'Unica77Mono';
            src: url('https://assets.analogue.co/type/unica77mono-regular.b11b328c00813bd505122b5280cd3ca4.woff2')
                format('woff2'),
              url('https://assets.analogue.co/type/unica77mono-regular.e1fcb259b45fdc50a16e7cc5439cc2e4.woff')
                format('woff');
            font-weight: 100 400;
            font-style: italic;
            font-display: swap;
          }
        `}</style>
        <Script id="dev-ad" src="/dev-ad.js" />
      </PlausibleProvider>
    </>
  )
}
