import Head from 'next/head'
import React from 'react'
import classNames from 'classnames/bind'
import styles from './v3.module.scss'

const cx = classNames.bind(styles)

type Props = {
  el?: keyof JSX.IntrinsicElements
  size?: '3xl' | '2xl' | 'xl' | 'l' | 'm' | 's' | 'sc' | 'xs' | '2xs' | '3xs'
  weight?: 'bold' | 'medium' | 'regular' | 'thin'
  family?: 'default' | 'mono'
  className?: string
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>
export type TextProps = NativeAttrs & Props

const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  el = 'p',
  size = 'm',
  children,
  weight = 'regular',
  family = 'default',
  className = '',
  ...props
}) => {
  const El = el
  const mono = family === 'mono'
  let classes = cx('text', `size_${size}`, `weight_${weight}`, { mono })
  if (className) {
    classes += ` ${className}`
  }
  return (
    <El className={classes} {...props}>
      {children}
    </El>
  )
}

export default Text

const FONT_FILES = [
  'circular-light.e41b1ae54b4a3dc789f64c5c8684cff0.woff2',
  'circular-thin.5fd4b3e6c719865ba3fd84b26b9ff6f7.woff2',
  'circular-regular.4445da5b8f9ac63a0fa7151b5029f567.woff2',
  'circular-medium.d5f19ad80c7ce40747a0b5ca8f3c25b5.woff2',
]

export const PreloadType = () => {
  const typeCDN = 'https://assets.analogue.co/type/'
  return (
    <Head>
      {FONT_FILES.map((file) => (
        <link
          key={file}
          rel="preload"
          as="font"
          crossOrigin="crossOrigin"
          type="font/woff2"
          href={`${typeCDN}${file}`}
        />
      ))}
    </Head>
  )
}
